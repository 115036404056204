export const images = {
    logoMain: require('./images/logo.png').default,
    logoWhite: require('./images/logo-white.png').default,
    Facebook: require('./images/streaming-img/img-09.jpg').default, 
    Microsoft: require('./images/streaming-img/img-10.jpg').default, 
    Linkedin: require('./images/streaming-img/img-11.jpg').default, 
    Youtube: require('./images/streaming-img/img-12.jpg').default,
    Boxcast: require('./images/streaming-img/img-13.jpg').default,
    Twitch: require('./images/streaming-img/img-14.jpg').default,
    Streamsopt: require('./images/streaming-img/img-15.jpg').default,
    Churchstreaming: require('./images/streaming-img/img-16.jpg').default,
    profileImg: require('./images/img-01.jpg').default,
    profileImgtwo: require('./images/img-02.jpg').default,
    profileImagePlaceHolder: require('./images/place-holder.jpg').default,
}