import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
import LazyDashboardPage from "../pages/user/Dashboard";
import LazyBillingPage from "../pages/user/Billing-Info";
import LazyLoginPage from "../pages/LoginPage";
import LazySignupPage from "../pages/SignupPage";
import LazyForgotPasswordPage from "../pages/Forgot-PasswordPage";
import LazyLatestPage from "../pages/user/Latest-Stream";
import LazyDownloadPage from "../pages/user/Download";
import LazySubscribePage from "../pages/user/Subscribe";
import LazyStreamPage from "../pages/user/Stream-Detail";
import LazyadminDashboardPage from "../pages/admin/adminDashboard";
import LazyadminStreamPage from "../pages/admin/adminStream";
import LazyStreamStatisticPage from "../pages/admin/StreamStatistic";
import PrivateRoutes from "./PrivateRoutes";
import { routes } from "./routesConstant";
import HeaderRoutes from "./HeaderRoutes";
import SidebarRoutes from "./SidebarRoutes";
import { useSelector } from "react-redux";
import {
  selectHeaderToken,
  selectToken,
  selectUser,
} from "../store/auth/selector";
import LazyProfilePage from "../pages/ProfilePage";
import { HttpService } from "../services";
import AdminHeaderRoutes from "./AdminHeaderRoutes";
import { PaymentSuccessPage } from "../pages/user/payment-success";
import SubscriptionsPage from "../pages/admin/subscriptions/allSubscriptions";
import ResetPasswordPage from "../pages/Reset-Password/ResetPassword.page";

const MainRoutes = () => {
  const access_token: any = useSelector(selectToken);
  const user: any = useSelector(selectUser);
  const token: any = useSelector(selectHeaderToken);
  HttpService.setToken(token);

  return (
    <BrowserRouter>
      <Switch>
        <Route path={routes.login} exact component={LazyLoginPage} />
        <Route path={routes.signup} exact component={LazySignupPage} />
        <Route
          path={routes.forgotpassword}
          exact
          component={LazyForgotPasswordPage}
        />
        <Route
          path={routes.resetpassword}
          exact
          component={ResetPasswordPage}
        />

        {user?.roles?.includes("admin") ? (
          <AdminHeaderRoutes>
            <PrivateRoutes
              path={routes.adminDashboard}
              authToken={access_token}
              component={LazyadminDashboardPage}
            />
            <PrivateRoutes
              path={routes.adminStream}
              authToken={access_token}
              component={LazyadminStreamPage}
            />
            <PrivateRoutes
              path={routes.adminstreamstatistic}
              authToken={access_token}
              component={LazyStreamStatisticPage}
            />
            <PrivateRoutes
              path={routes.profile}
              authToken={access_token}
              component={LazyProfilePage}
            />
          </AdminHeaderRoutes>
        ) : user ? (
          <HeaderRoutes>
            <PrivateRoutes
              path={routes.dashboard}
              authToken={access_token}
              component={LazyDashboardPage}
            />
            <PrivateRoutes
              path={routes.billing}
              authToken={access_token}
              component={LazyBillingPage}
            />
            <PrivateRoutes
              path={routes.latest}
              authToken={access_token}
              component={LazyLatestPage}
            />
            <PrivateRoutes
              path={routes.stream}
              authToken={access_token}
              component={LazyStreamPage}
            />
            <PrivateRoutes
              path={routes.profile}
              authToken={access_token}
              component={LazyProfilePage}
            />
            <PrivateRoutes
              path={routes.download}
              authToken={access_token}
              component={LazyDownloadPage}
            />
            <PrivateRoutes
              path={routes.subscribe}
              authToken={access_token}
              component={LazySubscribePage}
            />
            <PrivateRoutes
              path={routes.paymentsuccess}
              authToken={access_token}
              component={PaymentSuccessPage}
            />

            {/* <PrivateRoutes path={routes.adminDashboard} authToken={access_token}  component={LazyadminDashboardPage} />
                        <PrivateRoutes path={routes.adminStream} authToken={access_token}  component={LazyadminStreamPage} />
                        <PrivateRoutes path={routes.adminstreamstatistic} authToken={access_token}  component={LazyStreamStatisticPage} />
                        <PrivateRoutes path={routes.adminprofile} authToken={access_token}  component={LazyProfilePage} /> */}
            <PrivateRoutes
              path={routes.viewSubscriptions}
              authToken={access_token}
              component={SubscriptionsPage}
            />
          </HeaderRoutes>
        ) : (
          <Route path={routes.login} exact component={LazyLoginPage} />
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default MainRoutes;
