import { createSlice } from '@reduxjs/toolkit'

export interface ITypes {
    description: String,
    startTime: String,
    endTime: String
}

const initialState = {
    events: []
}

const slice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        events: (state, action) => {
            state.events = action.payload.events;
        },
        clearEvents: (state) =>{
            state.events = [];
        }
    }
});

export const { reducer: eventReducer, actions: eventActions } = slice;