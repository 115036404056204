import React, { lazy, Suspense } from 'react'
const StreamPage = lazy(() => import('./Stream.page'))

const LazyStreamPage = (props: any) => {
    return (
        <Suspense fallback={null}>
            <StreamPage {...props} />
        </Suspense>
    )
}

export default LazyStreamPage
