import { createSlice } from '@reduxjs/toolkit'
import { act } from 'react-dom/test-utils';

const initialState = {
    meetings: <any>[],
    attendiesList: <any>[],
    meetingCaptions: <any>[],
    meetingDateTime: null,
    meetingTitle: null,
    actionItems: <any>[]
}

const slice = createSlice({
    name: "meeting",
    initialState: initialState,
    reducers: {
        getMeetings: (state, action) => {
            state.meetings = action.payload
        },
        meetingAttendies: (state, action) => {
            state.attendiesList = action.payload
        },
        inviteBot: (state, action) => {
            state.meetings = [...state.meetings, action.payload]
        },
        meetingCaptions: (state, action) => {
            state.meetingCaptions = action.payload
        },
        meetingDateTime: (state, action) => {
            state.meetingDateTime = action.payload
        },
        meetingTitle: (state, action) => {
            state.meetingTitle = action.payload
        },
        clearMeetings: (state) =>{
            state.meetings = [];
            state.attendiesList = [];
            state.meetingCaptions = [];
            state.meetingDateTime = null;
            state.meetingTitle = null;
            state.actionItems = []
        },
        addActionItems: (state, action) => {
            state.actionItems = action.payload
        },
        updateActionItems: (state, action) => {
            state.actionItems = [...state.actionItems, action.payload];
        }
    }
});

export const { reducer: meetingReducer, actions: meetingActions } = slice;