import { HttpService } from './base.service'

class PaymentService extends HttpService {
    private readonly prefix: string = "payment";

    /**
     * Basic Authenticate User
     * @paramdata
     */
    createProduct = (): Promise<any> => this.get(this.prefix + '/create-product')
    checkOutSession = (data:any): Promise<any> => this.post(this.prefix + '/create-checkout-session', data)
    createSubscription = (data:any): Promise<any> => this.post(this.prefix + '/create-subscription', data)
    getSubscription = (data:any): Promise<any> => this.post(this.prefix + '/retrieve-subscription', data)
    cancelSubscription = (data:any): Promise<any> => this.post(this.prefix + '/cancel-subscription', data)
}

export const paymentService = new PaymentService();

