import React, { lazy, Suspense } from 'react'
const ForgotPasswordPage = lazy(() => import('./ForgotPassword.page'))

const LazyForgotPasswordPage = (props: any) => {
    return (
        <Suspense fallback={null}>
            <ForgotPasswordPage {...props} />
        </Suspense>
    )
}

export default LazyForgotPasswordPage
