import { createSelector } from "@reduxjs/toolkit"

const selectDomain = (state: any) => state.auth

export const selectToken =createSelector(selectDomain, auth => auth.access_token)
export const selectHeaderToken =createSelector(selectDomain, auth => auth.headerToken)
export const selectUser =createSelector(selectDomain, auth => auth.user)
export const selectAllUser =createSelector(selectDomain, auth => auth.allUsers)
export const selectAuthInstance =createSelector(selectDomain, auth => auth.userAuthInstance)
export const selectPages =createSelector(selectDomain, auth => auth.pages)
export const selectTotalUsers =createSelector(selectDomain, auth => auth.total_users)
export const selectInActiveUsers =createSelector(selectDomain, auth => auth.inActiveUsers)
export const selectCurentPage =createSelector(selectDomain, auth => auth.current_page)
