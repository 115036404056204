import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { AdminHeaderComponent } from '../pages/admin/components'
import { HeaderComponent } from '../pages/user/components/Header.component'
import { HttpService } from '../services'
import { selectHeaderToken, selectUser } from '../store/auth/selector'
import { routes } from './routesConstant'

interface IProps {
    children: any
}
const HeaderRoutes = ({ children }: IProps) => {
    const token: any = useSelector(selectHeaderToken); 
    const user: any = useSelector(selectUser); 
   
    HttpService.setToken(token);
    const [isHover, setHover] = useState<boolean>(false)
    const [isResponsiveSideBar, setResponsiveSideBar] = useState<boolean>(false)
    const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(true)

    const hideShowSideBar = () => {
        setMobileNavOpen(!isMobileNavOpen);
    };

    const isAdmin = () => {
        if(user?.roles) return user?.roles.includes('admin');
    //    return window.location.href.includes('admin');
    }
    return (
        <div className={`rt-wrapper`}>
            {isAdmin() ? <AdminHeaderComponent /> : <HeaderComponent />}
            <main className={`rt-main rt-haslayout`}
                onMouseEnter={() => {
                    setHover(true)
                    setResponsiveSideBar(true)
                }}
                onMouseLeave={() => {
                    setHover(false)
                    setResponsiveSideBar(false)
                }}
            >
                
                {children}
            </main>
        </div>
    )
}

export default HeaderRoutes