import { createSlice } from '@reduxjs/toolkit'
import { act } from 'react-dom/test-utils';

const initialState = {
    streams: <any>[],
    pages: 1,
    per_page: 10,
    current_page: 1,
    total_streams: 0,
}

const slice = createSlice({
    name: "stream",
    initialState: initialState,
    reducers: {
        setStreams: (state, action) => {
            state.streams = action.payload.streams;
            state.current_page = action.payload.current_page;
            state.pages = action.payload.pages;
            state.per_page = action.payload.per_page;
            state.total_streams = action.payload.total_streams  
        },
        clearStreams: (state) => {
            state.streams = [];
        },
    }
});

export const { reducer: streamsReducer, actions: streamActions } = slice;