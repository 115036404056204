import React, { lazy, Suspense } from 'react'
const DownloadPage = lazy(() => import('./Download.page'))

const LazyDownloadPage = (props: any) => {

    return (
        <Suspense fallback={null}>
            <DownloadPage {...props} />
        </Suspense>
    )
}

export default LazyDownloadPage
