import { Button } from "react-bootstrap";

interface IProps {
    type?: 'button' | 'submit'
    label?: string;
    onClick?: any
    className?: string
    loading?: boolean
}
export const ButtonCustom = (props: IProps) => {

    const className = `at-btn ${props.className || ''}`;
    let _props = {
        ...props,
        type: props.type || 'button',
        className
    }
    const onClick = () => {
        if (props.onClick) props.onClick();
    }

    return (
        <button type={props.type || 'button'} onClick={onClick} className={className} disabled={props.loading}>
            {
                props.loading ? 'Please wait...' : props.label || 'Submit'
            }
        </button>
    )
}