import React, { lazy, Suspense } from 'react'
const AdminStreamPage = lazy(() => import('./adminStream.page'))

const LazyAdminStreamPage = (props: any) => {
    return (
        <Suspense fallback={null}>
            <AdminStreamPage {...props} />
        </Suspense>
    )
}

export default LazyAdminStreamPage
