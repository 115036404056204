import React, { lazy, Suspense } from 'react'
const StreamStatisticPage = lazy(() => import('./StreamStatistic.page'))

const LazyStreamStatisticPage = (props: any) => {
    return (
        <Suspense fallback={null}>
            <StreamStatisticPage {...props} />
        </Suspense>
    )
}

export default LazyStreamStatisticPage
