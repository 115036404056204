import React from 'react'
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { HttpService } from '../services';
import { selectHeaderToken } from '../store/auth/selector';
import { routes } from './routesConstant';

const PrivateRoutes = ({ component: Page, authToken, ...rest }: any) => {
    const token: any = useSelector(selectHeaderToken);
    HttpService.setToken(token);
    return (
        <Route {...rest} render={props => {
            return (
                <>
                    {authToken ? <Page {...props} /> : <Redirect to={routes.landing} />}
                </>
            )
        }} />
    );
}

export default PrivateRoutes