import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { userService } from '../../../services';
import { authActions } from '../../../store/auth/auth';
import { selectUser } from '../../../store/auth/selector';

export  const PaymentSuccessPage = () => {
    const location = useLocation();
    const dispatch = useDispatch(); 
    const user: any = useSelector(selectUser)

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const duration = query.get('duration');

    const getUserProfile = async () => {    
        try {
            const userProfile = await userService.getUserProfile()
            if(userProfile.status == 200){
                dispatch(authActions.login({
                    user: userProfile.data.data,
                })) 
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getUserProfile()
    },[])

    return (
        <div>
            <h1>Thanks for your order!</h1>
            {
                duration === 'MINUTES' ? 
                <div>
                    <p>
                        You have successfully purchased 60 minutes at $5, available minutes ({user.minuts_remaining}). 
                        You can now use these minutes in your app to stream content. For any further queries please
                        <br></br>
                        <a href="contact support@heisto.io">contact support@heisto.io</a>.
                    </p>
                </div>
                : 
                duration === "MONTH" ? 
                <div>
                    <p>
                        You have successfully subscribed to the monthly plan at $20, your subscription would be automatically updated after 30 days each month. 
                        For any further queries please contact
                        <br></br>
                        <a href="support@heisto.io">support@heisto.io</a>.
                    </p>
                </div> 
                : 
                duration === "YEAR" ? 
                <div>
                <p>
                    You have succesfully subscribed to the annual plan at $200, your subscription would be automatically updated after 365 days. 
                    For any further queries please contact
                    <br></br>
                    <a href="support@heisto.io">support@heisto.io</a>.
                </p>
                </div>
                :
                null
            }
        </div>
    );
};