import React, { lazy, Suspense } from 'react'
const LatestPage = lazy(() => import('./Latest.page'))

const LazyLatestPage = (props: any) => {
    return (
        <Suspense fallback={null}>
            <LatestPage {...props} />
        </Suspense>
    )
}

export default LazyLatestPage
