import React, { lazy, Suspense } from 'react'
const SignupPage = lazy(() => import('./Signup.page'))

const LazySignupPage = (props: any) => {
    return (
        <Suspense fallback={null}>
            <SignupPage {...props} />
        </Suspense>
    )
}

export default LazySignupPage
