import { Link } from 'react-router-dom'
import { routes } from '../../../routes/routesConstant'

require('dotenv').config()

interface IProps {

}
const SubscriptionsPage = ({ }: IProps) => {

    return (
        <div>
            <div className="rt-admindashboard">
                <div className="rt-themehead">
                    <h2>Subscriptions</h2>
                </div>
                <div className="rt-themetablebox">
                    <div className="rt-searchbox">
                        <form className="rt-formtheme rt-searchform">
                            <fieldset>
                                {/* <div className="rt-twocols"> */}
                                    <div className="form-group rt-inputwithicon m-0">
                                        <input type="text" className="form-control" placeholder="Search" />
                                        <i className="fa fa-search"></i>
                                    </div>
                            </fieldset>
                        </form>
                    </div>
                    <div className="rt-contentbox">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Email</th>
                                        <th className="text-center">Current Plan</th>
                                        <th className="text-center">Stream Channel</th>
                                        <th className="text-center">Last Stream Date</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td data-title="Username">
                                        <em>Name</em>
                                    </td>
                                    <td data-title="Email">
                                        <em>email</em>
                                    </td>
                                    <td data-title="Current Plan" className="text-center">
                                        <em>$5/60mins</em>
                                    </td>
                                    <td data-title="Last Stream Date" className="text-center">
                                        {/* <time dateTime="0 0">{moment(user.createdAt).format('ddd - MMMM DD, yyyy')}</time> */}
                                        <time dateTime="0 0">data</time>
                                    </td>
                                    <td data-title="Action" className="text-center">
                                        <ul className="rt-actionicons">
                                            <li>    
                                                <i className="far fa-trash-alt"></i>
                                            </li>
                                            <li>
                                                <Link to={routes.adminStream}><i className="fas fa-eye"></i></Link>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionsPage
