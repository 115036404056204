import React, { lazy, Suspense } from 'react'
const SubscribePage = lazy(() => import('./Subscribe.page'))

const LazySubscribePage = (props: any) => {

    return (
        <Suspense fallback={null}>
            <SubscribePage {...props} />
        </Suspense>
    )
}

export default LazySubscribePage
