import React, { useState } from 'react'
import { Link, Router, useLocation } from 'react-router-dom'
import { images } from '../../../assets/image'
import { routes } from '../../../routes/routesConstant'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../../../store/auth/selector'
import { authActions } from '../../../store/auth/auth'
import { eventActions } from '../../../store/events/events'
import { useHistory } from 'react-router-dom'
import { meetingActions } from '../../../store/meetings/meeting'
import { SiderbarComponent } from './Sidebar.component'
import { Dropdown } from 'react-bootstrap'
import { streamActions } from '../../../store/streams/stream'

interface IProps {

}

export const HeaderComponent = ({ }: IProps) => {
    const user: any = useSelector(selectUser)
    const dispatch = useDispatch();
    const history = useHistory();

    const logout = () => {
        dispatch(authActions.logout())
        dispatch(streamActions.clearStreams()) 
        history.replace('/')
    }
    const [sidebarToggle, setSidebarToggle] = useState<boolean>(true);
    const sidebarToggleHanlder = () => {
        setSidebarToggle(prev => !prev);
    }
    return (

        <div className="rt-header rt-haslayout">
            <div className="rt-topbar">
                <strong className="rt-logo">
                    <Link to="#"><img src={images.logoWhite} alt="" /></Link>
                </strong>
                <a href="#" className="rt-btnopensidebarmenu">
                    <i className="fa fa-bars" onClick={sidebarToggleHanlder}></i>
                </a>
                <Dropdown className="dropdown rt-themedropdown rt-manageprofiledropdown">
                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="rt-btndropdown">
                        <span className="rt-userprofileimg"><img src={user?.profileImage ? user?.profileImage : images.profileImagePlaceHolder} alt="" /></span>
                        <em>{user?.email}</em>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="rt-manageprofiledropdownmenu rt-themedropdownmenu">
                        <ul>
                            <li>
                                <Dropdown.Item href="/profile">Manage Profile</Dropdown.Item>
                            </li>
                            <li>
                                <Dropdown.Item href="#" onClick={logout}>Logout</Dropdown.Item>
                            </li>
                        </ul>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <SiderbarComponent sidebarToggle={sidebarToggle} sidebarToggleHanlder={sidebarToggleHanlder} />
        </div>
        

    )
}