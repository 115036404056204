import React, { lazy, Suspense } from 'react'
const BillingPage = lazy(() => import('./Billing.page'))

const LazyBillingPage = (props: any) => {

    return (
        <Suspense fallback={null}>
            <BillingPage {...props} />
        </Suspense>
    )
}

export default LazyBillingPage
