import React from 'react';

export const LoadingComponent = () => {
    return (
        <div className="rt-loader" >
            <div className="la-line-scale-party la-2x">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};