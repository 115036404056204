import Reactf, { useEffect, useState } from 'react'
import { images } from '../../assets/image'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { routes } from '../../routes/routesConstant'
import Swal from 'sweetalert2'
import { authService } from '../../services'
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputCustom } from '../../components/shared-components/form/input';
import { ButtonCustom } from '../../components/shared-components/button.component';
import { ForgotPassword, IDynamic } from '../../interfaces'
import { LoadingComponent } from '../../components/shared-components/loading.component'

require('dotenv').config()

const schema = yup.object().shape({
    newPassword: yup.string().min(6).required(),
    confirmPassword: yup.string().min(6).required().oneOf([yup.ref('newPassword'), null], 'Passwords does not match'),
    pin: yup.number().required().min(5),
});
const ResetPasswordPage = () => {
    const history = useHistory();
    const location = useLocation();
    const [showMsg, setShowMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [sErrors, setSErrors] = useState<IDynamic>({})
    const {email}:any = location.state

    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm<ForgotPassword>({
        mode: 'onBlur',
        resolver: yupResolver(schema)
    });
    
    const handleSubmitBtn = (e:any) =>{
        e.preventDefault()
    }

    const onSubmit = async (data: any) => {
        try {
            setIsLoading(true)
            const updateObj = {
                password: data.newPassword,
                email: email,
                pin: data.pin
            }
            const updatePass = await authService.verifyPin(updateObj)
            if(updatePass.data.status_code == 200) {
                setShowMsg(false)
                setIsLoading(false)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Password reset successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
                history.push(routes.login)
            }
        } catch (error:any) {
            setIsLoading(false)
            if(error.response.status == 401) {
                setShowMsg(true)
            }
        }
    }

    return (
        <div className="rt-wrapper p-0">
            <section className="rt-loginholder rt-forgotpasswordholder">
                <div className="rt-split">
                    <div className="rt-flexcontainer">
                        <div className="rt-formbox">
                            <div className="rt-loginlogobox">
                                <strong className="rt-logo"><Link to={routes.login}><img src={images.logoMain} alt="" /></Link></strong>
                                {isLoading ? <LoadingComponent /> : null}
                            </div>
                            <form className="rt-formtheme rt-forgotpasswordform" onSubmit={handleSubmit(onSubmit)} action="">
                                <fieldset>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <InputCustom type="password" name="newPassword" placeholder="Password" error={errors.newPassword || sErrors.newPassword} register={register} />
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <InputCustom type="password" name="confirmPassword" placeholder="Password" error={errors.confirmPassword || sErrors.confirmPassword} register={register} />
                                    </div>
                                    <div className="form-group">
                                        <label>Verification Code</label>
                                        <InputCustom type="password" name="pin" placeholder="PIN" error={errors.pin || sErrors.pin} register={register} />
                                        <em>{
                                            showMsg ? 'Incorrect PIN code.Please Enter correct PIN code' : null
                                        }</em>
                                    </div>
                                    <div className="form-group text-center">
                                        <a onClick={handleSubmitBtn}>
                                            <ButtonCustom className="rt-btn rt-btn-lg" type="submit" label="Update Password" onClick={handleSubmit(onSubmit)} />
                                        </a>
                                    </div>
                                    <div className="form-group text-center">
                                        <span className="m-0">Back to <Link to={routes.login}>Login</Link></span>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResetPasswordPage