import React, { useState } from 'react'
import { Link, Router, useLocation } from 'react-router-dom'
import { images } from '../../../assets/image'
import { routes } from '../../../routes/routesConstant'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../../../store/auth/selector'
import { authActions } from '../../../store/auth/auth'
import { eventActions } from '../../../store/events/events'
import { useHistory } from 'react-router-dom'
import { meetingActions } from '../../../store/meetings/meeting'

interface IProps {

}

export const AdminSiderbarComponent = ({ }: IProps) => {
    const user: any = useSelector(selectUser)
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation()

    const logout = () => {
        dispatch(authActions.logout())
        dispatch(eventActions.clearEvents()) 
        dispatch(meetingActions.clearMeetings())
        history.replace('/')
    }

    return (
        
        <div className="rt-sidebarnavigation">
            <div className="rt-sidebar">
                <div id="rt-navigation" className="rt-navigation">
                    <nav className="rt-sidebarnav">
                        <ul>
                            <li className={`${routes.adminDashboard === location.pathname ? 'rt-active' : ''}`}><Link to={routes.adminDashboard}><span>Users list</span></Link></li>
                            {/* <li className={`${routes.adminstreamstatistic === location.pathname ? 'rt-active' : ''}`}><Link to={routes.adminstreamstatistic}><span>Stream statistics</span></Link></li> */}
                            {/* <li className={`${routes.adminprofile === location.pathname ? 'rt-active' : ''}`}><Link to={routes.adminprofile}><span>My Profile</span></Link></li> */}
                            {/* <li className={`${routes.adminprofile === location.pathname ? 'rt-active' : ''}`}><Link to={routes.viewSubscriptions}><span>All Subscriptions</span></Link></li> */}
                            {/* <li className={`${routes.latest === location.pathname ? 'rt-active' : ''}`}><Link to={routes.latest}><span>Payment history</span></Link></li> */}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>

    )
}