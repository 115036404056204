import React, { lazy, Suspense } from 'react'
const ProfilePage = lazy(() => import('./Profile.page')) 
 
const LazyProfilePage = (props:any) => {
    return(
        <Suspense fallback={null}>
            <ProfilePage {...props} />
        </Suspense>
    )
}  

export default LazyProfilePage