
export const routes = {
    landing: '/',
    dashboard: '/dashboard',
    billing: '/billing',
    latest: '/latest',
    stream: '/stream',
    settings: '/settings',
    profile: '/profile',
    download: '/download',
    subscribe: '/subscribe',
    login: '/' ,
    signup: '/singup' ,
    forgotpassword: '/forgotpassword' ,
    resetpassword: '/resetpassword' ,
    adminDashboard: '/admindashboard',
    adminStream: '/adminstream',
    adminprofile: '/adminprofile',
    adminstreamstatistic: '/adminstreamstatistic',
    paymentsuccess: '/payment-success',
    viewSubscriptions: '/view-subscriptions',
} 