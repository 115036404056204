import React from "react";

interface IProps {
    register?: any
    key?: string
    name: string
    placeholder: string
    type: 'number' | 'email' | 'text' | 'password' | 'textarea'
    error?: any
    label?: string
    defaultValue?: string
    value?: string
    disabled?: boolean
}
export const InputCustom = (props: IProps) => {
    
    let error = (typeof props.error === 'object') ? props.error.message : props.error;
    let inputProps = {...props, ref: props.register};
    delete inputProps.register;

    const input = () => {

        switch(props.type) {
            case 'textarea':
                return <textarea className="form-control" {...inputProps}></textarea>
            default:
                return <input className="form-control" {...inputProps}/>;
        }
    }

    return (
        <React.Fragment>
            { props.label && <label>{props.label}</label>}
            { input() }
            <small className="text-danger">{error}</small>
        </React.Fragment>
    )
}