import React, { lazy, Suspense } from 'react'
const AdminDashboardPage = lazy(() => import('././adminDashboard.page'))

const LazyAdminDashboardPage = (props: any) => {
    return (
        <Suspense fallback={null}>
            <AdminDashboardPage {...props} />
        </Suspense>
    )
}

export default LazyAdminDashboardPage
