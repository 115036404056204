import React from 'react';
import { useSelector } from 'react-redux';
import './App.scss';
import MainRoutes from './routes/MainRoutes';
import { HttpService } from './services';
import { selectHeaderToken } from './store/auth/selector';

function App() {
  const token = useSelector(selectHeaderToken)
  if(token) HttpService.setToken(token)
  return (
    <div className="App">
      <MainRoutes />
    </div>
  );
}

export default App;
