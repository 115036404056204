import { createSlice } from '@reduxjs/toolkit'
import { act } from 'react-dom/test-utils';

const initialState = {
    user: null,
    access_token: null,
    headerToken: null,
    allUsers: [],
    pages: 1,
    per_page: 10,
    current_page: 1,
    total_users: 0,
    inActiveUsers: 0
}

const slice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            if(action.payload.user) {
                state.user = action.payload.user;
            }
            if(action.payload.access_token){
                state.access_token = action.payload.access_token;
            }
        },
        setUsers: (state, action) => {
            state.allUsers = action.payload.users
            state.current_page = action.payload.current_page;
            state.pages = action.payload.pages;
            state.per_page = action.payload.per_page;
            state.total_users = action.payload.total_users;
            state.inActiveUsers = action.payload.inActiveUsers
        },
        setToken: (state, action) => {
            state.headerToken = action.payload
        },
        logout: (state) =>{
            state.user = null;
            state.access_token= null;
            state.headerToken= null
        }
    }
});

export const { reducer: authReducer, actions: authActions } = slice;