import { HttpService } from './base.service'

class StreamService extends HttpService {
    private readonly prefix: string = "user";

    /**
     * Basic Authenticate User
     * @paramdata
     */
    getStreams = (data:any) : Promise<any> => this.get(this.prefix + '/streams', data)
}

export const streamService = new StreamService();