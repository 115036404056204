const dev = {
  /**
   * New
   */
  API_ENDPOINT: "https://api.heisto.io/api/v1", 
  // API_ENDPOINT: "http://localhost:3042/api/v1",

  /**
   * Old
   */
  // API_ENDPOINT: "https://dev-restream.renesistechdemo.com/api/v1",
  // API_ENDPOINT: "https://restreamer-api.herokuapp.com/api/v1",
};

const stag = {
  API_ENDPOINT: "",
};

const prod = {
  API_ENDPOINT: "",
};

const config = {
  ...(process.env.REACT_APP_STAGE === "prod" ||
  process.env.REACT_APP_STAGE === "production"
    ? prod
    : process.env.REACT_APP_STAGE === "staging"
    ? stag
    : dev),
};

export default config;
