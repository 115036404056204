import React, { useState } from 'react'
import { Link, Router, useLocation } from 'react-router-dom'
import { images } from '../../../assets/image'
import { routes } from '../../../routes/routesConstant'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../../../store/auth/selector'
import { authActions } from '../../../store/auth/auth'
import { eventActions } from '../../../store/events/events'
import { useHistory } from 'react-router-dom'
import { meetingActions } from '../../../store/meetings/meeting'

interface IProps {
    sidebarToggle: boolean,
    sidebarToggleHanlder: Function
}

export const SiderbarComponent:React.FC<IProps> = (props) => {
    const user: any = useSelector(selectUser)
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation()

    const logout = () => {
        dispatch(authActions.logout())
        dispatch(eventActions.clearEvents()) 
        dispatch(meetingActions.clearMeetings())
        history.replace('/')
    }

    return (
        
        <div className={props.sidebarToggle ? "rt-sidebarnavigation" : "rt-sidebarnavigation rt-showmenusidebar"}>
            <div className="rt-sidebar">
                <div id="rt-navigation" className="rt-navigation">
                    <nav className="rt-sidebarnav">
                        <ul>
                            <li className={`${routes.dashboard === location.pathname ? 'rt-active' : ''}`}><Link to={routes.dashboard}>{/* <i className="icon-dashboard"></i> */}<span>Link Account</span></Link></li>
                            <li className={`${routes.billing === location.pathname ? 'rt-active' : ''}`}><Link to={routes.billing}> {/* <i className="icon-integration"></i> */} <span>Billing Info</span></Link></li>
                            <li className={`${routes.profile === location.pathname ? 'rt-active' : ''}`}><Link to={routes.profile}> {/* <i className="icon-setting"></i> */}<span>My Profile</span></Link></li>
                            <li className={`${routes.latest === location.pathname ? 'rt-active' : ''}`}><Link to={routes.latest}> {/* <i className="icon-subscribe"></i> */} <span>Recent Streams</span></Link></li>
                            <li className={`${routes.download === location.pathname ? 'rt-active' : ''}`}><Link to={routes.download}> {/* <i className="icon-subscribe"></i> */} <span>Download App</span></Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>

    )
}