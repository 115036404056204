import React, { lazy, Suspense } from 'react'
const DashboardPage = lazy(() => import('./Dashboard.page'))

const LazyDashboardPage = (props: any) => {
    return (
        <Suspense fallback={null}>
            <DashboardPage {...props} />
        </Suspense>
    )
}

export default LazyDashboardPage
