import { HttpService } from './base.service'

class AuthService extends HttpService {
    private readonly prefix: string = "auth";

    /**
     * Basic Authenticate User
     * @paramdata
     */
    resetPassword = (data:any): Promise<any> => this.post(this.prefix + '/reset-password', data)
    forgotPassword = (data:any): Promise<any> => this.post(this.prefix + '/forgot-password', data)
    getVerifyPin = (data:any): Promise<any> => this.post(this.prefix + '/get-verify-pin', data)
    verifyPin = (data:any): Promise<any> => this.post(this.prefix + '/verify-pin', data)
    signup = (data: any): Promise<any> => this.post(this.prefix + '/signup', data)
    login = (data: any): Promise<any> => this.post(this.prefix + '/login', data)
    googleLogin = (data: any): Promise<any> => this.post(this.prefix + '/social-login', data)
     
    // getUserProfile = (): Promise<any> => this.post(this.prefix + '/signup', data)
    // account/me
}

export const authService = new AuthService();

