import React, { lazy, Suspense } from 'react'
const LoginPage = lazy(() => import('./Login.page'))

const LazyLoginPage = (props: any) => {
    return (
        <Suspense fallback={null}>
            <LoginPage {...props} />
        </Suspense>
    )
}

export default LazyLoginPage
