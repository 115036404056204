import { HttpService } from './base.service'

class UserService extends HttpService {
    private readonly prefix: string = "account";
    private readonly prefix2: string = "user";

    /**
     * Basic Authenticate User
     * @paramdata
     */
    getUserProfile = (): Promise<any> => this.get(this.prefix + '/me')
    getAllUsers = (data:any) : Promise<any> => this.get(this.prefix2, data)
    removeUser = (id:any) : Promise<any> => this.post(this.prefix2 + '/remove-user', id)
    updateAdminProfile = (data:any, headers:any) : Promise<any> => this.post(this.prefix + '/update',data,headers)
}

export const userService = new UserService();

